import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import johnGoncalvesWlneMeetTheCandidates from '../../images/media/videos/john-goncalves-wlne-meet-the-candidates.webp'
import johnGoncalvesWjarRi01DemCongressionalPrimaryDebate2 from '../../images/media/videos/john-goncalves-wjar-ri-01-dem-congressional-primary-debate-2.webp'
import johnGoncalvesWpriRi01DemCongressionalPrimaryDebate from '../../images/media/videos/john-goncalves-wpri-ri-01-dem-congressional-primary-debate.webp'

import bostonGlobeLogo from '../../images/media/press/boston-globe-logo.webp'
import brownDailyHeraldLogo from '../../images/media/press/brown-daily-herald-logo.webp'
import brownPoliticalReviewLogo from '../../images/media/press/brown-political-review-logo.webp'
import eastSideMonthlyLogo from '../../images/media/press/east-side-monthly-logo.webp'
import ecoRINewsLogo from '../../images/media/press/eco-ri-news-logo.webp'
import fallRiverReporterLogo from '../../images/media/press/fall-river-reporter-logo.webp'
import festivalBalletProvidenceLogo from '../../images/media/press/festival-ballet-providence-logo.webp'
import goLocalProvLogo from '../../images/media/press/golocalprov-logo.webp'
import heraldNewsLogo from '../../images/media/press/the-herald-news-logo.webp'
import heyRhodyLogo from '../../images/media/press/hey-rhody-logo.webp'
import msnLogo from '../../images/media/press/msn-logo.webp'
import patchLogo from '../../images/media/press/patch-logo.webp'
import projoLogo from '../../images/media/press/projo-logo.webp'
import providenceBusinessFirstLogo from '../../images/media/press/providence-business-first-logo.webp'
import providenceBusinessNewsLogo from '../../images/media/press/providence-business-news-logo.webp'
import providenceCityCouncilLogo from '../../images/media/press/providence-city-council-logo.webp'
import providenceMonthlyLogo from '../../images/media/press/providence-monthly-logo.webp'
import steveAhlquistLogo from '../../images/media/press/steve-ahlquist-logo.webp'
import thePublicsRadioLogo from '../../images/media/press/the-publics-radio-logo.webp'
import upriseRILogo from '../../images/media/press/uprise-ri-logo.webp'
import usNewsAndWorldReportLogo from '../../images/media/press/us-news-and-world-report-logo.webp'
import wjarLogo from '../../images/media/press/wjar-logo.webp'
import wlneLogo from '../../images/media/press/wlne-logo.webp'
import wpriLogo from '../../images/media/press/wpri-logo.webp'

const featuredPress = [
  {
    "logo": `${thePublicsRadioLogo}`,
    "date": "5/5/2023",
    "headline": "As the son of an immigrant mother, I understand better than anyone else in this field the struggles of working families in Rhode Island.",
    "quote": "With rising inflation, it's getting harder every single day for Rhode Islanders to get by. My goal is to go to DC, and fight for those people and advocate for them every single day.<br /><br />And ultimately, <strong>every single day, I wake up at the crack of dawn, to serve students, to serve families, to serve my community...I was raised in Providence, I was raised in CD-1</strong>.<br /><br />So I think I'm the right person to lead during this time.",
    "linkUrl": "https://thepublicsradio.org/episode/political-roundtable-goncalves-on-his-run-in-cd1-providence-schools-and-taxes-for-non-profits-"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "7/24/2023",
    "headline": "I'm also a teacher. That's what differentiates me from the rest of the people on this stage.",
    "quote": "<strong>This work is not theoretical for me.</strong><br /><br />I grew up in poverty, to a single parent mother in the city of Providence, and being a person of color, my lived experience is exactly why I'm in this fight. There are so many people who need folks like me to be a part of these fights.",
    "linkUrl": "https://www.bostonglobe.com/2023/07/24/metro/democrats-agree-issues-differ-background-experience/"
  },
  {
    "logo": `${steveAhlquistLogo}`,
    "date": "7/26/2023",
    "headline": "We need strong leaders in Washington who aren't just going to use all the talking points that sound good, but people who have dealt with those experiences in a real way. I think that separates me from the field, in addition to being a teacher.",
    "quote": "During the height of the pandemic, there was a woman who reached out to me and was afraid to go out. She was an elderly woman. She was afraid to go to the grocery store because of the challenges associated with that. In response, we launched a program called Produce in the Parks that allowed people, particularly seniors, to access fresh fruits and vegetables at a park where they could pay with SNAP and EBT. <strong>By solving one person's problem, we were able to solve the problem for hundreds of people. That's the kind of leadership I've exhibited at the local level and that's the job of a Congressperson.</strong> Your job is to deliver the best and the highest quality constituent services.",
    "linkUrl": "https://steveahlquist.substack.com/p/pvd-city-councilmember-john-goncalves"
  },
]

const videos = [
  {
    "logo": `${johnGoncalvesWpriRi01DemCongressionalPrimaryDebate}`,
    "date": "8/29/2023",
    "headline": "WPRI 12 - RI-01 Democratic Congressional Primary Debate - John Goncalves - Closing Statement",
    "quote": "\"I grew up in a single-parent household.  And despite growing up in poverty, I remember when I was 12 years old, <strong>my mom would go around to every single event that Congressman Cicilline was having and say, \'Mayor, please &mdash; help my son to get into this school.\'</strong> That took me on the path from Providence Public Schools to Brown University.<br /><br />Washington isn't working for us. <strong>We need a fierce leader at the federal level who's going to fight for affordable housing.  Someone's who's going to fight for our climate crisis.  Someone who's going to fight for reproductive rights.  Someone who is going to put you first.  That's exactly why I'm in this race.</strong>  And I hope I can earn your support on September 5th.\"",
    "linkUrl": "https://www.youtube.com/watch?v=RsVSVzb0pQQ&t=195s"
  },
  {
    "logo": `${johnGoncalvesWjarRi01DemCongressionalPrimaryDebate2}`,
    "date": "9/1/2023",
    "headline": "NBC 10 (WJAR) - RI-01 Democratic Congressional Primary Debate - John Goncalves - Cost of Living, Jobs, and Education",
    "quote": "\"<strong>As someone who grew up in poverty, I understand better than anybody on this stage the challenges that people are facing out there.</strong>  The cost of living is a serious, serious issue that we need to address at the federal level.<br /><br />Like some of my colleagues here, I agree &mdash; we need to address social security.  <strong>But we can't just keep the status quo on social security.  That's why at the federal level, I support the Social Security Expansion Act</strong>, which would expand social security benefits to our seniors.<br /><br /><strong>But also the Paycheck Fairness Act, which is something at the Congressional level that I would support to raise wages.  We need to make sure that we are investing in jobs and education that put people first,</strong> and that's exactly what I would do in Congress.\"",
    "linkUrl": "https://www.instagram.com/p/Cwu-wD0OvUw/"
  },
  {
    "logo": `${johnGoncalvesWlneMeetTheCandidates}`,
    "date": "8/23/2023",
    "headline": "ABC6 (WLNE) - Meet the Candidates - John Goncalves",
    "quote": "\"I think <strong>there are some distinctions</strong> in our platform.<br /><br />For example, I'm someone who supports the Social Security Expansion Act.  So &mdash; <strong>there are folks who just want to maintain the status quo on social security.  I would vote to expand it.</strong><br />...<br />When we think about <strong>the cost of housing, the cost of living</strong> in Rhode Island &mdash; these are big concerns, and <strong>I've been doing that work on the ground floor at the local level for years and years, and I think that's an important distinction</strong>.<br /><br />You know &mdash; <strong>I'm not parachuting in from somewhere else.  I've been in the community doing the work.</strong>  I'm born and bred.  You know &mdash; I grew up in Providence.  I'm a teacher at the Wheeler School.  And I think <strong>those deep-rooted connections really set me apart in this field</strong>, and I think we're going to see that play out on Election Day.\"",
    "linkUrl": "https://www.youtube.com/watch?v=C8NoOHE0YYM"
  },
]

const opEds = [
  {
    "logo": `${projoLogo}`,
    "date": "5/13/2023",
    "headline": "It's time for the city's nonprofit institutions to pay their fair share.",
    "linkUrl": "https://www.providencejournal.com/story/opinion/columns/2023/05/13/its-time-for-the-citys-nonprofit-institutions-to-pay-their-fair-share/70212735007/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "4/18/2022",
    "headline": "Redeveloping the Superman building will breathe new life into Downtown Providence",
    "linkUrl": "https://www.bostonglobe.com/2022/04/18/metro/redeveloping-superman-building-will-breathe-new-life-into-downtown-providence/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "2/21/2020",
    "headline": "My Turn: John Goncalves: Let's stop shifting burden on East Side property owners and look at the entire system holistically",
    "linkUrl": "https://www.providencejournal.com/story/opinion/2020/02/21/john-goncalves-lets-stop-shifting-burden-east-side-property-owners-look-entire-system-holistically/1664097007/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "11/3/2021",
    "headline": "Brown University Can and Should Do More For The People of Providence",
    "linkUrl": "https://www.bostonglobe.com/2021/11/03/metro/brown-university-can-should-do-more-people-providence/"
  }
]

const press = [
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "7/25/2023",
    "headline": "Providence City Council will take legal action against 'sweetheart' tax breaks for wealthy developer",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2023/07/25/metro/providence-city-council-will-take-legal-action-against-sweetheart-tax-breaks-wealthy-developer/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "7/20/2023",
    "headline": "City Council to meet Tuesday, debate recourse over tax breaks for Buff Chace",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2023/07/20/city-council-to-meet-tuesday-debate-lawsuit-over-tax-breaks-for-buff-chace/70437107007/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/16/2023",
    "headline": "Councilors aim to make public bathrooms more accessible in Providence",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/councilors-aim-to-make-public-bathrooms-more-accessible-in-providence/"
  },
  {
    "logo": `${heraldNewsLogo}`,
    "date": "6/14/2023",
    "headline": "Providence's India Street renamed as Portugal Parkway to honor Portuguese heritage, contributions to R.I.",
    "quote": "",
    "linkUrl": "https://www.heraldnews.com/story/news/local/ojornal/2023/06/14/providence-india-street-renamed-to-portugal-parkway-to-honor-portuguese-heritage-contributions-r-i/70323974007/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/12/2023",
    "headline": "India Street renamed Portugal Parkway in Providence",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/india-street-renamed-portugal-parkway-in-providence/"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "5/1/2023",
    "headline": "Council member proposes honorary designation of 'Portugal Parkway' for India Street",
    "quote": "",
    "linkUrl": "https://turnto10.com/news/local/portugal-parkway-india-street-fox-point-neighborhood-providence-rhode-island-city-council-resolution-portuguese-heritage-cape-verde-ireland"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "4/24/2023",
    "headline": "Providence councilman pushes to change India Street name",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/providence-leader-pushes-change-india-street-name/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "3/27/2023",
    "headline": "Providence Councilman John Goncalves announces run for Congress",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/elections/providence-councilman-john-goncalves-announces-run-for-congress/"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "3/27/2023",
    "headline": "Providence City Council member John Goncalves joins crowded race for Congress",
    "quote": "",
    "linkUrl": "https://turnto10.com/politics/1st-district-special-election/providence-city-council-member-john-goncalves-democratic-primary-congress-washington-david-cicilline-education-working-families-union-jobs-gun-violence-climate-change-student-debt"
  },
  {
    "logo": `${thePublicsRadioLogo}`,
    "date": "3/27/2023",
    "headline": "Goncalves joins the crowded Democratic field in CD1",
    "quote": "",
    "linkUrl": "https://thepublicsradio.org/article/goncalves-joins-the-crowded-democratic-field-in-cd1"
  },
  {
    "logo": `${projoLogo}`,
    "date": "3/27/2023",
    "headline": "Providence Councilman John Goncalves is sixth candidate in CD1 race to succeed Cicilline",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/politics/2023/03/27/providence-councilman-golcalves-enters-race-to-replace-rep-cicilline/70052116007/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "3/27/2023",
    "headline": "Providence City Councilor Goncalves enters race for Congress",
    "quote": "",
    "linkUrl": "https://pbn.com/providence-city-councilor-goncalves-enters-race-for-u-s-congress/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "3/27/2023",
    "headline": "Providence Councilman Goncalves is running for Congress",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2023/03/27/metro/providence-councilman-goncalves-is-running-congress/"
  },
  {
    "logo": `${wlneLogo}`,
    "date": "3/27/2023",
    "headline": "Providence city councilor enters race for 1st Congressional District seat",
    "quote": "",
    "linkUrl": "https://www.abc6.com/providence-city-councilor-enters-race-for-1st-congressional-district-seat/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "4/7/2022",
    "headline": "There's a renewed effort to make private universities pay property taxes in Rhode Island",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2022/04/07/metro/theres-renewed-effort-make-private-universities-pay-property-taxes-rhode-island/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "3/24/2022",
    "headline": "Documents Show Top 195 Commission Officials Did Not Know Regulations While Selecting Developer",
    "quote": "\"Two days later, Davis drafted a response letter to Zurier and the Councilor John Goncalves.\"",
    "linkUrl": "https://www.golocalprov.com/business/documents-show-top-195-comm-officials-did-not-know-regulations-while-select"
  },
  {
    "logo": `${projoLogo}`,
    "date": "3/9/2022",
    "headline": "Providence city council candidate calls for city-level Green New Deal. Is it possible?",
    "quote": "\"The Journal reached out to current council members who are not term limited in order to gauge their potential support for a Green New Deal. Councilman John Goncalves of Ward 1 responded with complete support. In a statement, Goncalves echoed Jones' call for cleaning up the port and the city, contending that it \"will require more than a plan but codifying our values via more aggressive decarbonization goals that truly ensure environmental justice.\" \"We need to take ambitious action at the municipal level since leaders are struggling to enact this kind of bold legislation at the federal and state level,\" Goncalves said. \"Providence has a historic opportunity to lead to mitigate the threat of our climate crisis.\"",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2022/03/09/city-council-candidate-providence-ri-vision-battle-climate-change/9426000002/"
  },
  {
    "logo": `${providenceBusinessFirstLogo}`,
    "date": "2/3/2022",
    "headline": "Developer chosen for Parcel 2 in I-195 District",
    "quote": "\"Ward 1 Councilman John Goncalves, who had been critical of the process, on Wednesday congratulated Urbanica and thanked commission",
    "linkUrl": "https://www.bizjournals.com/rhodeisland/news/2022/02/03/urbanica-parcel-2-195-winner.html"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "2/2/2022",
    "headline": "195 Commission picks developer for S. Water Street apartment building",
    "quote": "\"The 195 Commission — which markets and sells the state-owned land that used to be Interstate 195 — put a series of conditions on the developer after multiple neighbors and Councilman John Goncalves, D-Ward 1, raised concerns about the size and design of the building, blocked views of the river as well as traffic and parking issues...\"They pretty much responded to everything we urged them to do,” Goncalves said in a text message Wednesday evening. He issued a statement congratulating the developer and said he looked forward to working with Urbanica “to ensure design excellence and sensitivity to our wonderful neighborhoods.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/195-commission-picks-developer-for-s-water-street-apartment-building/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "1/21/2022",
    "headline": "\'Massive and intrusive\': Providence locals double down on waterfront apartment criticism",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2022/01/21/providence-residents-heavily-disapprove-proposed-six-story-building-development-riverfront/6584939001/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "1/20/2022",
    "headline": "Public Criticize Revised 195 Apartment Proposals",
    "quote": "\"These projects are still too large. The walls are too high,\" said Councilman John Goncalves who represents Ward 1 and the neighborhood of Fox Point.\"",
    "linkUrl": "https://www.golocalprov.com/business/Public-Criticize-Revised-195-Apartment-Proposals"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "1/20/2022",
    "headline": "Public Criticize Revised 195 Apartment Proposals",
    "quote": "“These projects are still too large. The walls are too high,” said Councilman John Goncalves who represents Ward 1 and the neighborhood of Fox Point",
    "linkUrl": "https://www.golocalprov.com/business/Public-Criticize-Revised-195-Apartment-Proposals"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "1/11/2022",
    "headline": "Neighbors vexed by riverfront apartments proposed on old 195 land",
    "quote": "\"Tayo Heuser and Jeff Shore, a married couple who live on Benefit Street, are among those who signed a letter sent by Councilman John Goncalves to state leaders raising concerns about the three proposals currently under consideration.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/neighbors-vexed-by-riverfront-apartments-proposed-on-old-195-land/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "1/11/2022",
    "headline": "Providence neighbors scramble to soften impact of waterfront apartments",
    "quote": "\"Locals, troubled by the thought of looking out at a massive structure obstructing waterfront views and altering the skyline, are up in arms. With the help of City Councilman John Goncalves, they have amplified their concerns.On Tuesday, Goncalves sent a letter to Gov. Dan McKee and I-195 Redevelopment District Commission Chairman Robert Davis, imploring them to ensure that the “final decision on the proposal happens 'with the community' rather than simply 'to the community.'”",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2022/01/11/six-story-building-proposed-providence-river-near-pedestrian-bridge-angers-residents-views-parking/9157253002/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "1/11/2022",
    "headline": "Neighbors vexed by riverfront apartments proposed on old 195 land",
    "quote": "\"A married couple who live on Benefit Street, are among those who signed a letter sent by Councilman John Goncalves to state leaders raising concerns about the three proposals currently under consideration. The ire over Parcel 2 is part of a larger request by Goncalves, who represents the sections of the city in Fox Point, the Jewelry District and downtown that contain the former 195 land, to include the community in a more official way in the development decisions.\n\nHe's calling on the 195 Commission and the governor to form a task force made up of neighborhood abutters and businesses who can help form the “request for proposals” — the solicitation for developers — before they even go out.\n\nThe proposals and designs for buildings should incorporate “sensitivity to the historic vibrancy of our neighborhood,” Goncalves said.\n\nHe added that he was concerned about the commission “diverting from the original mission of creating a true Providence Innovation & Design District and life-sciences mecca full of vibrant 'knowledge-based' jobs in information technology, engineering, biomedical, biotechnology, and other fast-growing sectors.\" \n\nGoncalves is also asking for a comprehensive road plan to head off potential traffic problems caused by an influx of new development.",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/neighbors-vexed-by-riverfront-apartments-proposed-on-old-195-land/"
  },
  {
    "logo": `${wlneLogo}`,
    "date": "1/6/2022",
    "headline": "Providence City Councilors send open letter to Governor regarding COVID-19 response",
    "quote": "\"Providence City Councilors shared an open letter sent to Governor Dan McKee late Thursday Evening explaining their concerns over the COVID-19 response in the state.\n\nThe letter was signed by...Councilman John Goncalves (Ward 1)",
    "linkUrl": "https://www.abc6.com/providence-city-councilors-send-open-letter-to-governor-regarding-covid-19-response/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "1/2/2022",
    "headline": "Providence councilor rails against Parcel 2 residential development plans",
    "quote": "\"The residential property developments proposed for Parcel 2 in downtown Providence are too tall, lack green space and need more parking, according to a city councilor who is opposing the process. Ward 1 Councilman John Goncalves recently called on Gov. Daniel J. McKee and the I-195 Redevelopment District Commission to reconsider the process.\"",
    "linkUrl": "https://pbn.com/providence-councilor-rails-against-parcel-2-residential-development-plans/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "1/2/2022",
    "headline": "Boston-based Urbanica chosen for new apartment buildings by Providence River pedestrian bridge",
    "quote": "\"The Commission received dozens of letters from nearby residents and stakeholders, including from Councilman John Goncalves of Ward 1. Some who lived near the parcel laid out their concerns regarding the height of the proposed building and called it an “atrocity.\" Others called for additional parking spaces and said there was a loss of green space. Members of the commission said they met with Goncalves to address his concerns and that their talks have been “positively received.\" Goncalves issued a statement to the Globe Wednesday",
    "linkUrl": "https://www.bostonglobe.com/2022/02/02/metro/boston-based-urbanica-chosen-new-apartment-buildings-by-providence-river-pedestrian-bridge"
  },
  {
    "logo": `${brownPoliticalReviewLogo}`,
    "date": "12/28/2021",
    "headline": "South Water Travail: Why Did Powerful Providence Institutions Oppose a Bike Lane Project?",
    "quote": "\"Criticisms of the city's communication efforts were certainly overblown. In a statement on October 12th, Councilman John Goncalves, who represents the area that contains the bike path, detailed the many opportunities for feedback that he and the city organized over the past two years.",
    "linkUrl": "https://brownpoliticalreview.org/2021/12/travail-over-the-south-water-street-trail-the-battle-over-sustainable-infrastructure-in-providence/"
  },
  {
    "logo": `${wlneLogo}`,
    "date": "11/18/2021",
    "headline": "Mayor Elorza to join environmental partners to release composting report",
    "quote": "\"Elorza will be joined by city Councilman John Goncalves, Zero Waste Providence Organizer Debbie Schimberg, Harvest Cycle Program Coordinator Ella Kirkpatrick Kotner, Director of Sustainability Leah Bamberger, and Providence resident and composter Kristina Hines.\"",
    "linkUrl": "https://www.abc6.com/mayor-elorza-to-join-environmental-partners-to-release-composting-report/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "11/3/2021",
    "headline": "Brown University Can and Should Do More For The People of Providence",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/11/03/metro/brown-university-can-should-do-more-people-providence/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "10/22/2021",
    "headline": "Lines Drawn as Paint Dries on Remodeled South Water Street",
    "quote": "\"In an Oct. 12 statement, City Council member John Goncalves responded to the business abutters and RIDOT by saying the project was the product of two years of planning. He noted DPD and the City Council office sent community mailings, and several public meetings were held between March 2019 and June 2021",
    "linkUrl": "https://www.ecori.org/transportation/2021/10/22/lines-drawn-as-paint-dries-on-remodeled-south-water-street"
  },
  {
    "logo": `${projoLogo}`,
    "date": "10/21/2021",
    "headline": "DOT drops challenge to South Water Street Trail project in Providence",
    "quote": "\"Responding to the DOT's announcement, City Councilman John Goncalves, who represents the area where the bike path lies and has been a strong advocate for the project, thanked the state and federal agencies",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2021/10/21/providence-south-water-street-trail-project-challenge-dropped/6122862001/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "10/20/2021",
    "headline": "Leaf Blower Battle - Providence City Council Members Propose New Limits",
    "quote": "\"Now, three members of the Providence City Council are proposing some limits on leaf blowers. East Side Councilors Nirva LaFortune (Ward 3 and a candidate for mayor), Helen Anthony (Ward 2) and John Goncalves (Ward 1) are pushing the new limits",
    "linkUrl": "https://www.golocalprov.com/news/leaf-blower-battle-providence-city-council-members-propose-new-limits"
  },
  {
    "logo": `${providenceBusinessFirstLogo}`,
    "date": "10/14/2021",
    "headline": "Zoning board denies variance to Narragansett Improvement Co. for portside materials storage",
    "quote": "",
    "linkUrl": "https://www.bizjournals.com/rhodeisland/news/2021/10/14/narragansett-denied-waterfront-use-variance.html"
  },
  {
    "logo": `${wlneLogo}`,
    "date": "10/8/2021",
    "headline": "Providence City Councilors call for vaccination requirement for all school staff",
    "quote": "\"The COVID-19 vaccine is safe and effective. It is the best way for school employees to protect themselves as well as the students they serve. With many Providence students still too young for vaccination, it is important to ensure that teachers who care for these children all day, five days a week, do not go unvaccinated and untested,” said Councilman John Goncalves.\"",
    "linkUrl": "https://www.abc6.com/providence-city-councilors-call-for-vaccination-requirement-for-all-school-staff/"
  },
  {
    "logo": `${patchLogo}`,
    "date": "10/8/2021",
    "headline": "City Clerk Removed + School Vaccine Mandate",
    "quote": "\"Councilman John Goncalves proposed a resolution Thursday asking Gov. McKee and Education Commissioner Angélica Infante-Greene to mandate that all K-12 teachers and school employees get a COVID-19 vaccine or submit to weekly testing",
    "linkUrl": "https://patch.com/rhode-island/providence/city-clerk-removed-school-vaccine-mandate"
  },
  {
    "logo": `${projoLogo}`,
    "date": "10/8/2021",
    "headline": "Members of the Providence City Council propose resolution calling for mandatory vaccine for teachers, school employees",
    "quote": "\"Councilman John Goncalves proposed a resolution Thursday asking Gov. McKee and Education Commissioner Angélica Infante-Greene to mandate that all K-12 teachers and school employees get a COVID-19 vaccine or submit to weekly testing",
    "linkUrl": "https://www.providencejournal.com/story/news/education/2021/10/08/providence-city-council-considers-resolution-calling-teacher-vaccines/6048711001/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "10/7/2021",
    "headline": "Providence City Council calls for vaccine requirements for K-12 teachers, school employees",
    "quote": "\"Councilman John Goncalves, of Ward 1, proposed a resolution asking Governor Dan McKee and Education Commissioner Angélica Infante-Green Thursday night to impose a statewide policy mandating all teachers and school employees receive the COVID-19 vaccine or comply with weekly testing”",
    "linkUrl": "https://www.bostonglobe.com/2021/10/07/metro/providence-city-council-calls-vaccine-requirements-k-12-teachers-school-employees/"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "9/14/2021",
    "headline": "Residents detail systemic issues at Fox Point Manor",
    "quote": "\"Recently, Ward 1 Councilman John Goncalves '13 MA'15 said he has taken “a hands-on approach” toward Fox Point Manor. He has connected some tenants with legal services, and talks regularly with the residents and management.\n\nGoncalves emphasized that the problems seniors are facing in Fox Point Manor are not limited to one HUD-subsidized building. “This is a microcosm of the bigger issues there,” he said.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/09/14/residents-detail-systemic-issues-fox-point-manor/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "9/8/2021",
    "headline": "Providence Police “Major” Controversy - 10 More Council Members Call on Elorza to Reclassify Role",
    "quote": "\"The following letter was signed by Council Member John Igliozzi, was co-signed by council members Pedro Espinal, James Taylor, Nicholas Narducci, Mary Kay Harris, Carmen Castillo, Michael Correia, John Goncalves, Jo-Ann Ryan, David Salvatore, and Oscar Vargas",
    "linkUrl": "https://www.golocalprov.com/news/providence-police-major-controversy-10-more-council-members-call-on-elorza"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "9/2/2021",
    "headline": "So you want to represent Providence's East Side in the Rhode Island Senate? Here's what to know",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/09/02/metro/so-you-want-represent-providences-east-side-rhode-island-senate-heres-what-know/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "9/1/2021",
    "headline": "Should Providence buy the 'Superman building'?",
    "quote": "\"Councilman John Goncalves, whose ward includes downtown, has proposed asking Jorge Elorza's administration to study \"the efficacy\" of having the city or state take the Industrial Trust Building by eminent domain \"as a means of preserving this Rhode Island landmark.\"",
    "linkUrl": "https://www.providencejournal.com/story/news/politics/2021/09/01/should-providence-seize-superman-building/5683101001/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "8/24/2021",
    "headline": "Providence's Great Streets Initiative Gears Up Even as South Water Street Project Hits Speed Bump",
    "quote": "\"There were several meetings and communications sent with opportunities to provide comment,” City Council member John Goncalves wrote in an Aug. 18 statement.\"",
    "linkUrl": "https://www.ecori.org/transportation/2021/8/24/providences-great-streets-initiative-gears-up-even-as-south-water-street-project-hits-speed-bump"
  },
  {
    "logo": `${providenceCityCouncilLogo}`,
    "date": "8/18/2021",
    "headline": "Statement from Councilman John Goncalves Regarding South Water Street Trail",
    "quote": "",
    "linkUrl": "https://council.providenceri.gov/2021/08/18/statement-from-councilman-john-goncalves-regarding-south-water-street-trail/"
  },
  {
    "logo": `${patchLogo}`,
    "date": "8/14/2021",
    "headline": "City Of Providence: City Of Providence Announces Construction Schedule Of 'Great Streets' Projects",
    "quote": "\"In Providence, it's critical that we improve the way we approach street design to accommodate multi-modal transportation and promote the safety of all travelers,\" said Councilman John Goncalves, Ward 1.\"",
    "linkUrl": "https://patch.com/rhode-island/providence/city-providence-city-providence-announces-construction-schedule-great"
  },
  {
    "logo": `${projoLogo}`,
    "date": "8/13/2021",
    "headline": "11th-Hour Push Against Providence South Water Bike Trail",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2021/08/13/businesses-launch-11-th-hour-push-against-south-water-bike-trail/5567763001/"
  },
  {
    "logo": `${patchLogo}`,
    "date": "8/7/2021",
    "headline": "2 Boston Men Killed In Overnight Providence Shooting: Police",
    "quote": "\"Providence City Councilman John Goncalves called for the council end its August recess and hold an emergency meeting with police and the mayor.\"",
    "linkUrl": "https://patch.com/rhode-island/cranston/2-boston-men-killed-overnight-providence-shooting-police"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "8/5/2021",
    "headline": "City Council president asks McKee to send state police to Providence to help stop crime",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/08/05/metro/city-council-president-asks-mckee-send-state-police-providence-help-stop-crime/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "8/4/2021",
    "headline": "Providence FOP Issues Blistering Rebuke of Mayor Elorza's Leadership",
    "quote": "\"Council member John Goncalves who represents Fox Point and College Hill  (Ward 1) said in an interview in 2020, “I [am] committed to doing that work, to reallocating police funding… towards housing, homelessness, social services, jobs… We're navigating a very complex political process from a legality perspective it's hard to do that.\"",
    "linkUrl": "https://www.golocalprov.com/news/providence-fop-issues-blistering-rebuke-of-mayor-elorzas-leadership"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "8/4/2021",
    "headline": "ATV and dirt bike riders drag woman out of her car and beat her while stopped at a traffic light in Providence",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/08/04/metro/atv-dirt-bike-riders-drag-woman-out-her-car-beat-her-while-stopped-traffic-light-providence/"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "7/16/2021",
    "headline": "Providence City Council members call for review of Achievement First deal",
    "quote": "\"Councilors Helen Anthony, Nirva LaFortune, and John Goncalves introduced a resolution Thursday night, insisting the council have approval authority over the deal to merge the charter school with Fortes Elementary.\"",
    "linkUrl": "https://www.turnto10.com/news/local/providence-city-councilors-call-for-review-of-achievement-first-deal"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "7/15/2021",
    "headline": "Providence City Council members call for review of Achievement First deal",
    "quote": "",
    "linkUrl": "https://turnto10.com/news/local/providence-city-councilors-call-for-review-of-achievement-first-deal"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "7/15/2021",
    "headline": "Providence city councilors want approval authority over Achievement First, Fortes Elementa",
    "quote": "\"Councilors Helen Anthony, Nirva LaFortune and John Goncalves will introduce a resolution Thursday that would require the council to approve the deal, which allows the charter system to move into Fortes Elementary starting July 1.\"",
    "linkUrl": "https://www.turnto10.com/i-team/education-advocate/providence-city-councilors-want-approval-authority-over-achievement-first-fortes-elementa"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "7/14/2021",
    "headline": "College Hill historic district expansion passes despite prior University objections",
    "quote": "\"Ward 1 City Councilman John Goncalves '13 MA'15, a cosponsor of the ordinance, emphasized that this was an important step in maintaining the historic character of the East Side.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/07/14/college-hill-historic-district-expansion-passes-despite-prior-university-objections/"
  },
  {
    "logo": `${fallRiverReporterLogo}`,
    "date": "7/14/2021",
    "headline": "Providence launching Guaranteed Income pilot program",
    "quote": "\"PROVIDENCE, RI &mdash; Mayor Jorge O. Elorza today joined Providence City Councilman John Goncalves (Ward 1), Sen. Tiara Mack, Rep. Anastasia P. Williams, Amos House, Dorcas International Institute of Rhode Island, ONE Neighborhood Builders, the Rhode Island Foundation, the United Way of Rhode Island, and community members to announce the launch of the Providence Guaranteed Income pilot program.\"",
    "linkUrl": "https://fallriverreporter.com/providence-launching-guaranteed-income-pilot-program/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "7/14/2021",
    "headline": "Prov. City Council narrowly OKs $540M budget, plus federal relief spending",
    "quote": "\"Councilors Helen Anthony, John Goncalves, Kat Kerwin, Nirva LaFortune, Rachel Miller and James Taylor voted against it.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/prov-city-council-narrowly-oks-540m-budget-plus-federal-relief-spending/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "7/6/2021",
    "headline": "Brown University rejects all East Side residents' recommendations for proposed residence halls",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/07/06/metro/brown-university-rejects-all-east-side-residents-recommendations-proposed-residence-halls/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "6/30/2021",
    "headline": "Future of Providence's Crook Point bridge in question after fire",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2021/06/30/fire-burns-providences-crook-point-bridge/7808797002/"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "6/29/2021",
    "headline": "Neighborhood News: New Trees in Fox Point, Historic District Approved, Special Election for Ward 15 & Volunteer in Summit",
    "quote": "\"Ward 1 Councilman John Goncalves sponsored the ordinance, working closely with policy and planning staff.\"",
    "linkUrl": "https://providenceonline.com/stories/neighborhood-news-new-trees-in-fox-point-historic-district-approved,37287"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "6/1/2021",
    "headline": "On Providence's East Side, a battle brews between Brown University and longtime residents",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/2021/06/01/metro/providences-east-side-battle-brews-between-brown-university-longtime-residents/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "5/29/2021",
    "headline": "Elorza's Speed Hump Plan Criticized By Former Top Aide Smiley",
    "quote": "John Goncalves who represents Ward 1 on the City Council -- the Fox Point and Wayland area that includes the Henderson Bridge speed hump wrote in a letter to constituents, “While many residents in this area have called for traffic calming measures here, and while we recognize that speed bumps and other traffic calming infrastructure are necessary to mitigate speeding and reckless driving in our City, these speed bumps are too high and must be rectified in a way that does not inconvenience or endanger drivers, vehicles, and pedestrians.\"",
    "linkUrl": "https://www.golocalprov.com/politics/elorzas-speed-hump-plan-takes-a-hit-as-his-former-top-aide-criticizes-the-s"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "5/27/2021",
    "headline": "Neighborhood News: Duck & Bunny Demo, DownCity Design Requests Proposals & Designating a New Local Historic District",
    "quote": "\"The ordinance has the strong support of Ward 1 Councilman John Goncalves, as well as several other City councilors. PPS is working to win Brown University's support to include three of their architecturally significant properties in the district (1 Young Orchard/105 Benevolent, 154 Hope, and 137 Waterman).\"",
    "linkUrl": "https://providenceonline.com/stories/neighborhood-news-duck-bunny-demo-downcity-design-requests-proposals-designating-a-new-local,37129"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "5/18/2021",
    "headline": "Community groups ask for changes to Brook St. dorm plan",
    "quote": "\"Multiple East Side neighborhood groups and Ward 1 Councilman John Goncalves '13 MA '15 released a letter Monday demanding changes to the University proposal for a two-building residence hall on Brook Street, including shrinking the proposed structure and adding back retail space that had been removed from previous proposals.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/05/18/community-groups-ask-changes-brook-st-dorm-plan"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "5/10/2021",
    "headline": "Providence City Council pushes for state study of sex workers",
    "quote": "\"The council's resolution was co-sponsored by Council President Pro Tempore Pedro Espinal (Ward 10), Councilwoman Nirva LaFortune (Ward 3), Councilwoman Helen Anthony (Ward 2), Councilor Rachel Miller (Ward 13), and Councilman John Goncalves (Ward 1). It passed Thursday by voice vote.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/providence-city-council-pushes-for-state-study-of-sex-workers/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "5/6/2021",
    "headline": "Providence Makes Room for Five Dozen Restitution Trees",
    "quote": "\"Early this year, the Tree Restitution Committee, initiated by Ward 1 City Council member John Goncalves and consisting of the Parks Department's Forestry Division, the Providence Neighborhood Planting Program, Save The Trees PVD, National Grid and other entities, was created to plan the planting of restitution trees to replace those National Grid had to chop down to replace some 2 miles of aging underground transmission lines along parts of Clarkson, Admiral, Dollar and South Main streets.\"",
    "linkUrl": "https://www.ecori.org/smart-growth/2021/5/5/providence-makes-room-for-restitution-trees"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "4/30/2021",
    "headline": "GLO reaches tentative agreement with University on pay raise",
    "quote": "",
    "linkUrl": "https://www.browndailyherald.com/article/2021/05/glo-reaches-tentative-agreement-with-university-on-pay-raise"
  },
  {
    "logo": `${festivalBalletProvidenceLogo}`,
    "date": "4/19/2021",
    "headline": "CITY COUNCILMAN JOHN GONCALVES TO JOIN FESTIVAL BALLET PROVIDENCE BOARD",
    "quote": "\"PROVIDENCE, RI (April 19, 2021) &mdash; The Festival Ballet Providence Board of Trustees proudly announces the addition of Providence City Councilman John Goncalves (Ward 1) as its newest Trustee.\"",
    "linkUrl": "https://festivalballetprovidence.org/about/press/city-councilman-john-goncalves-to-join-festival-ballet-providence-board/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "4/10/2021",
    "headline": "Nesi's Notes: April 10",
    "quote": "\"Councilman John Goncalves, who represents most of 02903, said constituents who live downtown have reached out in frustration over being excluded.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/ted-nesi/nesis-notes-april-10/"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "4/8/2021",
    "headline": "Ward 1 residents criticize revised Brook Street dorm plans",
    "quote": "\"Ward 1 Councilman John Goncalves '13 MA'15 hosted the panel, featuring several University representatives involved in the project to answer residents' questions about the revisions to the Brook Street residence hall plans.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/04/08/ward-1-residents-criticize-revised-brook-street-dorm-plans/"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "4/8/2021",
    "headline": "Ward 1 residents criticize revised Brook Street dorm plans",
    "quote": "\"Ward 1 Councilman John Goncalves '13 MA'15 hosted the panel, featuring several University representatives involved in the project to answer residents' questions about the revisions to the Brook Street residence hall plans.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/04/08/ward-1-residents-criticize-revised-brook-street-dorm-plans/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "4/8/2021",
    "headline": "Providence City Council approves belated budget with leadership in flux",
    "quote": "\"She voted against the budget, along with Councilors John Goncalves and Nirva LaFortune. All three represent parts of the East Side.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/providence-city-council-approves-belated-budget-with-leadership-in-flux/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "4/6/2021",
    "headline": "Former Duck & Bunny building in Providence demolished",
    "quote": "\"In a statement, Providence City Councilman John Goncalves expressed his disappointment in the lack of communication between the owners and the city regarding the demolition.\"",
    "linkUrl": "https://www.wpri.com/business-news/former-duck-bunny-building-in-providence-demolished/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "4/6/2021",
    "headline": "Brown Cuts Back Size of New Dorm Project",
    "quote": "\"Councilman John Goncalves called the dorms \"not aesthetically or functionally in tune with the neighborhood.\"",
    "linkUrl": "https://www.golocalprov.com/news/brown-cuts-back-size-of-new-dorm-project"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "4/5/2021",
    "headline": "City Council Calls Out Port of Providence's Polluting Ways",
    "quote": "\"Ward 1 council member John Goncalves, who represents Fox Point, portions of College Hill and Wayland Square, and much of the Innovation District, said he shares his colleague's hope that the city can move to more environmentally friendly business practices along Allens Avenue. “One of the greatest crises that we are facing as a society is climate change, and the damage that is being done to the environment by businesses like scrap yards and other 'dirty' businesses,” he wrote. “As a city and state, we want businesses to operate and flourish here, but we can't have that at the expense of our residents and our natural environment.\"",
    "linkUrl": "https://ecori.org/2021-4-5-city-council-calls-out-port-of-providences-polluting-ways/"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "4/5/2021",
    "headline": "University revises plans for Brook Street dorms",
    "quote": "\"Community members will be able to provide input on the revised plan, including during an upcoming virtual community meeting hosted by Ward 1 Councilman John Goncalves '13 MA'15 on April 8.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/04/05/university-revises-plans-brook-street-dorms/"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "4/3/2021",
    "headline": "Neighborhood News: A space made available to Providence's neighborhood associations free of charge",
    "quote": "",
    "linkUrl": "https://providenceonline.com/stories/neighborhood-news-a-space-made-available-to-providences-neighborhood-associations-free-of,37462"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "2/27/2021",
    "headline": "Brown Versus the Neighbors, Again &mdash; Architecture Critic Morgan",
    "quote": "\"Councilman John Goncalves called the dorms \"not aesthetically or functionally in tune with the neighborhood.\"",
    "linkUrl": "https://www.golocalprov.com/business/brown-versus-the-neighbors-again-architecture-critic-morgan"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "2/26/2021",
    "headline": "Elorza's Push for Legislation to Legalize ATVs on City Streets Draws Strong Reaction",
    "quote": "\"Councilman John Goncalves spoke to the city placing speed bumps in India Point to try and stop drag racing.\"",
    "linkUrl": "https://www.golocalprov.com/news/elorza-pushing-for-legislation-to-legalize-atvs-draws-strong-reaction"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "2/11/2021",
    "headline": "Creation of new historic district on East Side faces University objections",
    "quote": "\"Ward 1 City Councilman John Goncalves '13 MA'15 told The Herald that additional regulation would not necessarily rule out further development.\"",
    "linkUrl": "https://www.browndailyherald.com/2021/02/11/creation-new-historic-district-east-side-faces-university-objections/#comments"
  },
  {
    "logo": `${projoLogo}`,
    "date": "2/4/2021",
    "headline": "Providence City Council wrestles once again with housing vouchers",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2021/02/04/providence-city-council-votes-initial-yes-ban-bias-against-housing-vouchers/4398746001/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "2/4/2021",
    "headline": "Jewelry District apartment project with 38 Studios link seeks tax break",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/politics/2021/02/04/jewelry-district-apartment-project-38-studios-link-seeks-tax-break/4387716001/"
  },
  {
    "logo": `${brownDailyHeraldLogo}`,
    "date": "1/27/2021",
    "headline": "Curfews bring challenges for Providence businesses",
    "quote": "",
    "linkUrl": "https://www.browndailyherald.com/2021/01/27/curfews-bring-challenges-providence-businesses/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "1/26/2021",
    "headline": "John 'Johnny' Britto, father figure in Fox Point neighborhood, dies at 87",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/story/news/local/2021/01/26/john-johnny-britto-father-fox-point-providence-dies-87/4270167001/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "1/14/2021",
    "headline": "Providence City Council to vote to block firings from Elorza's vaccine mandate",
    "quote": "\"It does not appear the council has enough votes to override a potential veto. Six councilors — John Goncalves, Helen Anthony, Nirva LaFortune, Rachel Miller, Kat Kerwin and David Salvatore — have said they are voting no on the ordinance. The council needs 10 out of 15 councilors to override a veto.",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/providence-city-council-to-vote-on-elorzas-vaccine-mandate/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "1/4/2021",
    "headline": "Climate Justice, Composting Among Providence's Environmental Initiatives for 2021",
    "quote": "The push for a new compost program came from the environmental advocacy group Zero Waste Providence. The group led the city's plastic bag ban campaign and organized reuse programs and business composting initiatives. The group, along with Groundwork Rhode Island, hopes to write the report, conduct surveys, and enact any initiatives that are adopted.\n\nThe resolution is sponsored by council member John Goncalves. Health and environmental advocates Clean Water Action Rhode Island and Healthy Babies Bright Futures are working with Goncalves and the Office of Sustainability to create an environmentally preferable purchasing (EPP) policy.\n\nThe resolution stems from a plan to ban single-use plastics at city buildings and schools. It grew to include increased use of renewable energy and purchases of non-toxic cleaning products. If approved, the city and schools would buy paper products with high-recycled content. Other purchases would be recyclable or made of recycled or reused material.\n\n“This broader approach not only helps us address single-use plastics but all products containing neurotoxic chemicals in city-owned facilities,” Goncalves said.",
    "linkUrl": "https://ecori.org/2021-1-4-y8fom3fcjww2qhoxhjazkuqvo9lta5/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "11/26/2020",
    "headline": "Rhode Islanders Are Thankful for Everything From Beating COVID To a “Return of Hugs” in the Future",
    "quote": "",
    "linkUrl": "https://www.golocalprov.com/news/rhode-islanders-are-thankful-for-everything-from-beating-covid-to-a-return"
  },
  {
    "logo": `${projoLogo}`,
    "date": "11/20/2020",
    "headline": "City council calls for commitment to diversify Providence Police",
    "quote": "\"Over the past five years, not a single Black Providence police sergeant has been promoted to lieutenant or captain, which would be a pathway to becoming a police major,” said Ward 1 City Councilman John Goncalves, who represents Fox Point, Wayland Square, College Hill, the Jewelry District and downtown, \"and it's critically important that the Police Department reflect the cultural, racial and gender diversity of the community which it serves.\"",
    "linkUrl": "https://www.providencejournal.com/story/news/crime/2020/11/20/city-council-calls-commitment-diversify-providence-police/6355977002/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "10/21/2020",
    "headline": "Providence Council requests periodic workshops for public on zoning practices",
    "quote": "\"The resolution was introduced by Councilman John Goncalves, who represents Fox Point and College Hill and other portions of the East Side.\"",
    "linkUrl": "https://pbn.com/providence-council-requests-periodic-workshops-for-public-on-zoning-practices/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "10/21/2020",
    "headline": "Pennrose chosen as developer for Parcel 9 in I-195 Redevelopment District, proposes two buildings",
    "quote": "",
    "linkUrl": "https://pbn.com/pennrose-chosen-as-developer-for-parcel-9-in-i-195-redevelopment-district-proposes-two-buildings/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "10/15/2020",
    "headline": "City Councilors to Mayor Elorza: Scrap amnesty pledge or cancel gun buyback",
    "quote": "\"Councilors John Igliozzi, Jo-Ann Ryan, John Goncalves and James Taylor all abstained from voting on the matter.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/city-councilors-to-mayor-elorza-scrap-amnesty-pledge-or-cancel-gun-buyback/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "9/23/2020",
    "headline": "I-195 commission considers mixed-income, residential development for Fox Point",
    "quote": "",
    "linkUrl": "https://pbn.com/i-195-commission-considers-mixed-income-residential-development-for-fox-point/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "9/7/2020",
    "headline": "City Council Opposes RIDOT's Kennedy Plaza Plan",
    "quote": "",
    "linkUrl": "https://ecori.org/2020-9-7-city-council-opposed-bus-hub-plan/"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "9/2/2020",
    "headline": "College Town or Ghost Town?",
    "quote": "\"City Councilman John Goncalves, one of the dynamic forces behind the Providence Coalition of Neighborhood Associations, recently elected representative of Ward 1, and a Brown graduate himself, acknowledges the difficult decisions colleges face with reopening and commends their relentless efforts in finding safe ways to do so.",
    "linkUrl": "https://providenceonline.com/stories/college-town-or-ghost-town,36009"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "9/2/2020",
    "headline": "Neighborhood News: Mural at Fox Point Elementary, Bioswales in Washington Park, Seasonal Parklets, and Read Island Project",
    "quote": "\"The mural was designed by Myles Dumas and implemented with the help of The Avenue Concept and City Councilor John Goncalves",
    "linkUrl": "https://providenceonline.com/stories/neighborhood-news,36162"
  },
  {
    "logo": `${projoLogo}`,
    "date": "8/29/2020",
    "headline": "Kennedy Plaza bus plan decried at rally",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20200829/kennedy-plaza-bus-plan-decried-at-rally"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "8/26/2020",
    "headline": "Providence councilors, residents fed up with illegal vehicles on streets",
    "quote": "\"Providence City Councilmen David Salvatore and John Goncalves told NBC 10 News they have both received a number of complaints from their constituents.\"",
    "linkUrl": "http://turnto10.com/news/local/providence-councilors-residents-fed-up-with-illegal-vehicles-on-streets"
  },
  {
    "logo": `${heyRhodyLogo}`,
    "date": "7/9/2020",
    "headline": "Can Providence Neighborhoods Save the City?",
    "quote": "\"John Goncalves brought Post-it notes in several colors, along with pens. There was also a larger poster board, with three marked categories: “Priority #1 (in your neighborhood),” “Priority 2,” and “Priority 3.\"",
    "linkUrl": "https://heyrhody.com/stories/from-south-county-with-love,35713"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "6/26/2020",
    "headline": "“Produce in The Park” to Launch in Fox Point in July",
    "quote": "\"The effort is led by Friends of Cabral Park, Providence Partnership for Parks, Helene Miller, iProv Fellow Eleanor Pereboom, and Councilman John Goncalves (Ward 1).\"",
    "linkUrl": "https://www.golocalprov.com/food/produce-in-the-park-to-launch-in-fox-point-in-july"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "6/22/2020",
    "headline": "Fate of Three Popular Providence Trees Tied to Utility Upgrades",
    "quote": "\"John Goncalves, Providence Ward 1 City Council member, is advocating that the trees somehow be kept.\"",
    "linkUrl": "https://www.ecori.org/smart-growth/2020/6/22/future-of-three-london-planetrees-remains-in-limbo"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "6/16/2020",
    "headline": "Five-story hotel proposed for College Hill moves forward to Providence City Council",
    "quote": "",
    "linkUrl": "https://pbn.com/five-story-hotel-proposed-for-college-hill-moves-forward-to-providence-city-council/"
  },
  {
    "logo": `${wlneLogo}`,
    "date": "6/12/2020",
    "headline": "More than 300 people petition National Grid not to cut down trees in Providence",
    "quote": "",
    "linkUrl": "https://www.abc6.com/more-than-300-people-petition-national-grid-not-to-cut-down-trees-in-providence/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "6/12/2020",
    "headline": "Side of the Rhode: Who's Hot and Who's Not? - June 12, 2020",
    "quote": "",
    "linkUrl": "https://www.golocalprov.com/politics/side-of-the-rhode-whos-hot-and-whos-not-june-12-2020"
  },
  {
    "logo": `${msnLogo}`,
    "date": "6/4/2020",
    "headline": "Goncalves wins seat on Providence City Council",
    "quote": "",
    "linkUrl": "https://www.msn.com/en-us/news/us/goncalves-wins-seat-on-providence-city-council/ar-BB158r2o"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/4/2020",
    "headline": "John Goncalves wins Ward 1 special election",
    "quote": "\"PROVIDENCE, R.I. (WPRI) — Democrat John Goncalves has won the special election to fill the open seat on the Providence City Council, in a race that was delayed for two months because of the COVID-19 pandemic.\"",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/john-goncalves-wins-ward-1-special-election/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "5/20/2020",
    "headline": "Providence commission approves 61-unit apartment building for Fox Point",
    "quote": "",
    "linkUrl": "https://pbn.com/providence-commission-approves-61-unit-apartment-building-for-fox-point/"
  },
  {
    "logo": `${upriseRILogo}`,
    "date": "3/20/2020",
    "headline": "Members of Providence City Council call on state to halt evictions during pandemic",
    "quote": "\"In an open letter to Rhode Island Governor Gina Raimondo, five members of the Providence City Council, Helen Anthony (Ward 2), Nirva LaFortune (Ward 3), Pedro Espinal (Ward 10), Kat Kerwin (Ward 12), Rachel Miller (Ward 13) and Special-Election Primary winner John Goncalves (Ward 1), are requesting a to halt evictions amid the COVID-19 pandemic.\"",
    "linkUrl": "https://upriseri.com/2020-03-20-pvd-city-council-covid-19/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "3/4/2020",
    "headline": "RI Election Results: Goncalves Declares Victory in Providence Ward 1, Giraldo Win House Seat in CF",
    "quote": "\"In a competitive three-ways Democratic primary for the First Ward in Providence, John Goncalves has declared victory in a race over Nick Cicchitelli and Anthony Santurri.\"",
    "linkUrl": "https://www.golocalprov.com/articles/ri-election-results-goncalves-declares-victory-in-providence-ward-1-giraldo"
  },
  {
    "logo": `${usNewsAndWorldReportLogo}`,
    "date": "3/4/2020",
    "headline": "Teacher Wins Democratic City Council Primary",
    "quote": "\"John Goncalves, a fourth-grade teacher and diversity coordinator at the independent Wheeler School, won the special election primary Tuesday to represent Ward 1.\"",
    "linkUrl": "https://www.usnews.com/news/best-states/rhode-island/articles/2020-03-04/teacher-wins-democratic-city-council-primary"
  },
  {
    "logo": `${upriseRILogo}`,
    "date": "3/4/2020",
    "headline": "John Goncalves wins PVD City Council Ward One primary; uncontested in general",
    "quote": "“I consider both my opponents Anthony Santurri and Nick Cicchitelli as community leaders on so many fronts and I am looking forward to working with them as we work to move Ward 1 forward,” said John Goncalves.\"",
    "linkUrl": "https://upriseri.com/2020-03-04-john-goncalves/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "3/3/2020",
    "headline": "Goncalves wins primary for Providence's Ward 1 seat",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20200303/goncalves-wins-primary-for-providencersquos-ward-1-seat"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "3/3/2020",
    "headline": "John Goncalves wins Ward 1 special election primary",
    "quote": "\"PROVIDENCE, R.I. (WPRI) — John Goncalves has won the Democratic primary for the City Council seat in Providence's Ward 1, all but guaranteeing he'll become the councilperson because there's no one else running in the general election.\"",
    "linkUrl": "https://www.wpri.com/news/elections/john-goncalves-wins-ward-1-special-election-primary/"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "2/22/2020",
    "headline": "Fate of Three Popular Providence Trees Tied to Utility Upgrades",
    "quote": "\"John Goncalves, Providence Ward 1 City Council member, is advocating that the trees somehow be kept.\"",
    "linkUrl": "https://www.ecori.org/smart-growth/2020/6/22/future-of-three-london-planetrees-remains-in-limbo"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "1/24/2020",
    "headline": "Neighbors say illegal bikes put safety at risk",
    "quote": "\"City Councilman John Goncalves told NBC 10 he's been working on an ordinance to address safety concerns over the ATVs since July, which is now going through final vetting.\"",
    "linkUrl": "https://turnto10.com/i-team/neighbors-say-illegal-bikes-put-safety-at-risk"
  },
  {
    "logo": `${providenceMonthlyLogo}`,
    "date": "12/18/2019",
    "headline": "Watch John Goncalves Bring Neighborhoods Together...",
    "quote": "\"House Speaker Nicholas Mattiello effectively pulled the plug on a Providence City Council proposal to create a two-tiered property tax structure last summer, but one could argue that Fox Point resident John Goncalves did just as much to stop it.\"",
    "linkUrl": "http://providenceonline.com/stories/watch-john-goncalves-bring-neighborhoods-together,34348"
  },
  {
    "logo": `${ecoRINewsLogo}`,
    "date": "12/9/2019",
    "headline": "Providence's Climate Justice Plan Needs to Start on Allens Avenue",
    "quote": "",
    "linkUrl": "https://www.ecori.org/green-opinions/2019/12/9/providences-climate-justice-plan-needs-to-start-on-allens-avenue"
  },
  {
    "logo": `${upriseRILogo}`,
    "date": "12/9/2019",
    "headline": "Neighborhood groups and politicians strongly support Providence's Climate Justice Plan",
    "quote": "",
    "linkUrl": "https://upriseri.com/2019-12-09-pvd-climate-justice-plan-support/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "11/21/2019",
    "headline": "R.I. looks at closing I-195 off-ramp in Providence, putting state on collision course with locals",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/metro/rhode-island/2019/11/21/looks-closing-off-ramp-providence-putting-state-collision-course-with-locals/It75rfewqmB0S44hgfev9I/story.html"
  },
  {
    "logo": `${upriseRILogo}`,
    "date": "11/15/2019",
    "headline": "Mayor Elorza meets with community groups to discuss state takeover of Providence Schools",
    "quote": "\"Utilizing community input to inform school improvement, and incorporating the voices of parents, students, and communities, is essential to the successful implementation of turnaround interventions and policies that will lead to positive, tangible outcomes for our students and children,” said John Goncalves, one of the founders/organizing members of the Providence Coalition of Neighborhood Associations.\"",
    "linkUrl": "https://upriseri.com/2019-10-15-state-takeover/"
  },
  {
    "logo": `${providenceBusinessNewsLogo}`,
    "date": "11/8/2019",
    "headline": "Providence giving troubled e-scooter, e-bike programs another go, but not everybody's on board",
    "quote": "",
    "linkUrl": "https://pbn.com/providence-giving-troubled-e-scooter-e-bike-programs-another-go-but-not-everybodys-on-board/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "10/15/2019",
    "headline": "Mayor Elorza meets with community groups to discuss state takeover of Providence Schools",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20191015/elorza-schools-takeover-will-be-long-process"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "10/9/2019",
    "headline": "Prov Neighborhood & Business Leaders Oppose RIDOT's Plan to Close Gano Street Off-Ramp",
    "quote": "",
    "linkUrl": "https://www.golocalprov.com/news/prov-neighborhood-business-leaders-oppose-ridots-plan-to-close-gano-street"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "10/9/2019",
    "headline": "RIDOT: Gano Street exit will temporarily reopen in coming weeks",
    "quote": "\"John Goncalves, a resident and board member of the Fox Point Neighborhood Association, said the consensus is clear.\"",
    "linkUrl": "https://www.wpri.com/green-living/transportation/ridot-plans-to-reopen-gano-street-exit-temporarily/"
  },
  {
    "logo": `${wjarLogo}`,
    "date": "10/9/2019",
    "headline": "Business owners sound off on possible plans to permanently close Gano Street off-ramp",
    "quote": "",
    "linkUrl": "http://turnto10.com/news/local/business-owners-sound-off-on-possible-plans-to-permanently-close-gano-street-off-ramp"
  },
  {
    "logo": `${upriseRILogo}`,
    "date": "9/11/2019",
    "headline": "Candidates for Ward 10 Providence City Council Special Election answer questions at community forums",
    "quote": "",
    "linkUrl": "https://upriseri.com/2019-09-11-ward-10-special-election/"
  },
  {
    "logo": `${bostonGlobeLogo}`,
    "date": "9/9/2019",
    "headline": "Providence leaders launch campaign for 'nightlife mayor'",
    "quote": "",
    "linkUrl": "https://www.bostonglobe.com/metro/rhode-island/2019/09/09/providence-leaders-launch-campaign-for-nightlife-mayor/ciFOdDBBWgqo87Oc9eG9pK/story.html"
  },
  {
    "logo": `${eastSideMonthlyLogo}`,
    "date": "8/1/2019",
    "headline": "",
    "quote": "",
    "linkUrl": "http://eastsidemonthly.com/stories/east-side-monthly-august-2019,32564"
  },
  {
    "logo": `${projoLogo}`,
    "date": "6/26/2019",
    "headline": "Panel explores options to homestead exemption plan",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20190626/providence-finance-committee-explores-options-after-house-kills-homestead-exemption-plan"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/26/2019",
    "headline": "Controversial Providence tax plan is off the table for this year",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/controversial-providence-tax-plan-is-off-the-table-for-this-year/"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/20/2019",
    "headline": "Senate panel OKs bill to enable controversial new Providence tax plan",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/senate-panel-passes-bill-to-enable-controversial-new-providence-tax-plan/"
  },
  {
    "logo": `${projoLogo}`,
    "date": "6/20/2019",
    "headline": "Senate panel OKs homestead exemption bill",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20190620/ri-senate-panel-oks-providence-homestead-exemption-bill"
  },
  {
    "logo": `${wpriLogo}`,
    "date": "6/17/2019",
    "headline": "East Siders sound off on City Council tax proposal",
    "quote": "",
    "linkUrl": "https://www.wpri.com/news/local-news/providence/east-siders-sound-off-on-city-council-tax-proposal/"
  },
  {
    "logo": `${goLocalProvLogo}`,
    "date": "6/13/2019",
    "headline": "Providence's East Siders Organizing to Fight New Tax Structure",
    "quote": "Providence's East Siders Organizing to Fight New Tax Structure",
    "linkUrl": "https://www.golocalprov.com/news/providences-east-siders-organizing-to-fight-new-tax-structure"
  },
  {
    "logo": `${projoLogo}`,
    "date": "6/2/2019",
    "headline": "R.I. Cape Verdean community celebrates culture, contributions in annual procession",
    "quote": "",
    "linkUrl": "https://www.providencejournal.com/news/20190602/ri-cape-verdean-community-celebrates-culture-contributions-in-annual-procession"
  }
]

const PressPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Press" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto pt-10 pb-4 px-4 sm:px-6 lg:px-8">
        <div className="mt-1 text-center">
          <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Press
          </p>
        </div>
      </div>
    </div>

    <div className="relative bg-gray-300 px-4 sm:px-6 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Featured Press</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {featuredPress.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: post.quote }}></p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Read More &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="relative bg-gray-300 px-4 sm:px-6 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Featured Videos</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {videos.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: post.quote }}></p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Watch The Full Video Clip &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="relative bg-gray-200 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Op-Eds</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {opEds.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500">{post.quote}</p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Read More &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="relative bg-gray-100 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
      <div className="relative max-w-7xl mx-auto pt-8 pb-8">
        <h1 className="font-bold text-6xl">Press Mentions</h1>
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {press.map((post) => (
            <div key={post.title} className="card flex flex-col shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <img className="w-full object-cover" src={post.logo} alt="" />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-orange-headline">
                    {post.date}
                  </p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{post.headline}</p>
                    <p className="text-lg text-gray-500">{post.quote}</p>
                  </div>
                  <a href={post.linkUrl} className="block mt-2" target="_blank">Read More &raquo;</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export default PressPage
